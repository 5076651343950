import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/fbert/OneDrive/Documents/wwwBertramFamilyCom/GatsbyJS/src/components/layout-mdx.js";
import { TwoCol, Main, Aside } from "../../../../components/felix-mdx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TwoCol mdxType="TwoCol">
      <Main mdxType="Main">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "630px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/bf1832d859963ac8f6fa34a33a79510a/828fb/audiowerk8b_med_hr.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "51%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgMF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABvn35ER4P/8QAGRAAAwADAAAAAAAAAAAAAAAAAQIDABAR/9oACAEBAAEFAmqzMHpk68Qb/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHBAAAQMFAAAAAAAAAAAAAAAAAQACERIgITJB/9oACAEBAAY/AnUkgTxbPWZNn//EABgQAQEBAQEAAAAAAAAAAAAAAAERAGEQ/9oACAEBAAE/IXcrGT23NILu4AQPf//aAAwDAQACAAMAAAAQgA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEAAwACAwAAAAAAAAAAAAABABExECFRYZH/2gAIAQEAAT8QaaMCS06bch2qCIn1lxmYXSr9qAQB4CUGAcf/2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Audiowerk 8",
                "title": "Audiowerk 8",
                "src": "/static/bf1832d859963ac8f6fa34a33a79510a/828fb/audiowerk8b_med_hr.jpg",
                "srcSet": ["/static/bf1832d859963ac8f6fa34a33a79510a/f93b5/audiowerk8b_med_hr.jpg 300w", "/static/bf1832d859963ac8f6fa34a33a79510a/b4294/audiowerk8b_med_hr.jpg 600w", "/static/bf1832d859963ac8f6fa34a33a79510a/828fb/audiowerk8b_med_hr.jpg 630w"],
                "sizes": "(max-width: 630px) 100vw, 630px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2>{`Description`}</h2>
        <p>{`Introduced at NAMM in January 1997, Audiowerk 8 was among the first (the second
to be precise) PCI-based multi-channel sound cards. Audiowerk 8 in conjunction
with Logic Audio formed a tightly integrated system: an affordable one-stop
solution for the ambitious musician.`}</p>
        <p>{`PCI bridges where rare at the time the project was started, competitors used the
AMCC bridge plus FPGAs to implement audio functionality. Instead, Audiowerk was
centered around Philips‘ SAA7146, a YUV-to-PCI bridge with just enough audio
functionality to build the product. This was the enabling factor for Audiowerk‘s
competitive advantage: A leap in quality and a drop in price. The concept was
indeed good enough to be closely copied by a well-known Korean competitor…`}</p>
        <p>{`Between 1997 and 2001, about 45.000 units of Audiowerk have been sold, a huge
success for Emagic. Customers included all kinds of musicians, but also industrial
and governmental customers with very interesting applications: voice announcement
systems for airports and train stations, temperature measurements in blast
furnaces, measurement of vibrations and resonances in automobiles, monitoring
of phone lines.`}</p>
        <p>{`Features`}</p>
        <ul>
          <li parentName="ul">{`2 analog inputs (pig tail)`}</li>
          <li parentName="ul">{`8 analog outputs (pig tail)`}</li>
          <li parentName="ul">{`16 bit converter resolution`}</li>
          <li parentName="ul">{`more than 90dB dynamic range`}</li>
          <li parentName="ul">{`1 digital input (RCA)`}</li>
          <li parentName="ul">{`1 digital output (RCA)`}</li>
          <li parentName="ul">{`sample rate 44.1kHz, 48kHz, tunable in 1Hz steps`}</li>
          <li parentName="ul">{`internal or external synchronization`}</li>
          <li parentName="ul">{`up to three units stackable to increase the number of I/Os`}</li>
          <li parentName="ul">{`PCI interface with bus-master DMA`}</li>
          <li parentName="ul">{`drivers for Windows 9x/ NT/ 2k, Mac OS 9, Mac OS X`}</li>
        </ul>
        <p>{`As a low-cost variant and ingredient to software bundles, Audiowerk 2 was
designed. Main differences: 2 analog outputs. Sample rate not tunable.`}</p>
      </Main>
      <Aside mdxType="Aside"></Aside>
    </TwoCol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      